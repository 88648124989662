import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IntroGuard } from './guards/intro.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';
// import { TabsPage } from './tabs/tabs.page';
// import { MenuPage } from './menu/menu.page';
import { AppComponent } from './app.component';
//     path: 'validar-qr-codeo/:id', // :id = ID da escala.
//     loadChildren: () => import('./pages/validar-qr-code/validar-qr-code.module').then( m => m.ValidarQrCodePageModule)

const routes: Routes = [
  {
    path: '',
    // component: AppComponent,
    children: [
      {path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule) },
      {path: 'intro', loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule) },
      {path: 'login',loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)},
      {path: 'welcome', loadChildren: () => import('./tab1/tab1.module').then(m => m.Tab1PageModule),canLoad: [AuthGuard,IntroGuard] },
      {path: 'calendario',loadChildren: () => import('./pages/calendario/calendario.module').then(m => m.CalendarioPageModule),canLoad: [AuthGuard,IntroGuard] },
      {path: 'logout', loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule) },
      {path: 'inventarios', loadChildren: () => import('./pages/inventarios/inventarios.module').then( m => m.InventariosPageModule),canLoad: [AuthGuard,IntroGuard]},
      {path: 'validar-qr-codeo/:id', loadChildren: () => import('./pages/validar-qr-code/validar-qr-code.module').then( m => m.ValidarQrCodePageModule),canLoad: [AuthGuard,IntroGuard] },
      {path: 'reconhecimento-facial/:id/:osinventario/:escritorio',loadChildren: () => import('./pages/reconhecimento-facial/reconhecimento-facial.module').then(m => m.ReconhecimentoFacialPageModule),canLoad: [AuthGuard,IntroGuard] },
      {path: 'ativos', loadChildren: () => import('./pages/ativos/ativos.module').then( m => m.AtivosPageModule),canLoad: [AuthGuard,IntroGuard] },
      {path: 'reconhecimento-facial-desktop/:id/:osinventario/:escritorio', loadChildren: () => import('./pages/reconhecimento-facial-desktop/reconhecimento-facial-desktop.module').then( m => m.ReconhecimentoFacialDesktopPageModule)},
      {path: 'financeiro', loadChildren: () => import('./pages/financeiro/prestacao-contas/prestacao-contas.module').then( m => m.PrestacaoContasPageModule),canLoad: [AuthGuard,IntroGuard] },
      {path: 'financeirocria-prestacao-conta', loadChildren: () => import('./financeirocria-prestacao-conta/financeirocria-prestacao-conta.module').then( m => m.FinanceirocriaPrestacaoContaPageModule),canLoad: [AuthGuard,IntroGuard]},
      {path: 'ponto-escritorio', loadChildren: () => import('./ponto-escritorio/ponto-escritorio.module').then( m => m.PontoEscritorioPageModule)},
      {
        path: 'refresh',
        redirectTo: '/welcome',
        pathMatch: 'full'
      },
      {
        path: '',
        redirectTo: '/intro',
        pathMatch: 'full'
      },

    ],

  },
  {
    path: 'infowelcome',
    loadChildren: () => import('./modal/infowelcome/infowelcome.module').then( m => m.InfowelcomePageModule)
  },
  {
    path: 'qrcode',
    loadChildren: () => import('./modal/qrcode/qrcode.module').then( m => m.QrcodePageModule)
  },
  {
    path: 'select-search-transferencia',
    loadChildren: () => import('./select-search-transferencia/select-search-transferencia.module').then( m => m.SelectSearchTransferenciaPageModule)
  },
  {
    path: 'info-escala',
    loadChildren: () => import('./modal/info-escala/info-escala.module').then( m => m.InfoEscalaPageModule)
  },
  {
    path: 'ativos-em-sua-posse',
    loadChildren: () => import('./modal/ativos-em-sua-posse/ativos-em-sua-posse.module').then( m => m.AtivosEmSuaPossePageModule)
  },
  {
    path: 'confirma-escala',
    loadChildren: () => import('./modal/confirma-escala/confirma-escala.module').then( m => m.ConfirmaEscalaPageModule)
  },
  {
    path: 'camera',
    loadChildren: () => import('./camera/camera.module').then( m => m.CameraPageModule)
  },
  {
    path: 'nova-page',
    loadChildren: () => import('./nova-page/nova-page.module').then( m => m.NovaPagePageModule)
  },
  {
    path: 'refresh',
    loadChildren: () => import('./refresh/refresh.module').then( m => m.RefreshPageModule)
  },
  {
    path: 'teste-layout',
    loadChildren: () => import('./teste-layout/teste-layout.module').then( m => m.TesteLayoutPageModule)
  },
    {
    path: 'change-password',
    loadChildren: () => import('./pages/profile/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'localnotification',
    loadChildren: () => import('./localnotification/localnotification.module').then( m => m.LocalnotificationPageModule)
  },
  {
    path: 'esqueci-minha-senha',
    loadChildren: () => import('./modal/esqueci-minha-senha/esqueci-minha-senha.module').then( m => m.EsqueciMinhaSenhaPageModule)
  },





];


// Original
// const routes: Routes = [
//   {
//     path: 'login',
//     loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
//     canLoad: [IntroGuard, AutoLoginGuard] // Check if we should show the introduction or forward to inside
//   },
//   {
//     path: 'intro',
//     loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
//   },
//   {
//     path: 'tabs',
//     loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
//     canLoad: [AuthGuard] // Secure all child pages
//   },
//   {
//     path: 'reconhecimento-facial/:id/:osinventario',
//     loadChildren: () => import('./pages/reconhecimento-facial/reconhecimento-facial.module').then(m => m.ReconhecimentoFacialPageModule),
//     canLoad: [AuthGuard] // Secure all child pages
//   },
//   {
//     path: 'reconhecimento-facial-desktop/:id/:osinventario',
//     loadChildren: () => import('./pages/reconhecimento-facial-desktop/reconhecimento-facial-desktop.module').then( m => m.ReconhecimentoFacialDesktopPageModule)
//   },

//   {
//     path: '',
//     redirectTo: '/login',
//     pathMatch: 'full'
//   },
//   {
//     path: 'inventarios',
//     loadChildren: () => import('./pages/inventarios/inventarios.module').then( m => m.InventariosPageModule)
//   },
//   {
//     path: 'mostra-escala-inventario',
//     loadChildren: () => import('./pages/mostra-escala-inventario/mostra-escala-inventario.module').then( m => m.MostraEscalaInventarioPageModule)
//   },
//   {
//     path: 'bater-ponto-escala',
//     loadChildren: () => import('./pages/bater-ponto-escala/bater-ponto-escala.module').then( m => m.BaterPontoEscalaPageModule)
//   },
//   {
//     path: 'validar-qr-codeo/:id', // :id = ID da escala.
//     loadChildren: () => import('./pages/validar-qr-code/validar-qr-code.module').then( m => m.ValidarQrCodePageModule)
//   },
//   {
//     path: 'localidade', // :id = ID da escala.
//     loadChildren: () => import('./pages/localidade/localidade.module').then( m => m.LocalidadePageModule)
//   },
//   {
//     path: 'meuespelhodeponto',
//     loadChildren: () => import('./pages/meuespelhodeponto/meuespelhodeponto.module').then( m => m.MeuespelhodepontoPageModule)
//   },
//   {
//     path: 'prestacao-contas',
//     loadChildren: () => import('./pages/financeiro/prestacao-contas/prestacao-contas.module').then( m => m.PrestacaoContasPageModule)
//   },
//   {
//     path: 'financeirocria-prestacao-conta',
//     loadChildren: () => import('./financeirocria-prestacao-conta/financeirocria-prestacao-conta.module').then( m => m.FinanceirocriaPrestacaoContaPageModule)
//   },
//   {
//     path: 'calendario',
//     loadChildren: () => import('./pages/calendario/calendario.module').then( m => m.CalendarioPageModule)
//   },
//   {
//     path: 'change-password',
//     loadChildren: () => import('./pages/profile/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
//   },
//   {
//     path: 'check-connection-model',
//     loadChildren: () => import('./pages/check-connection-model/check-connection-model.module').then( m => m.CheckConnectionModelPageModule)
//   },




// ];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
