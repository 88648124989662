// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// // Homologacação
// export const environment = {
  
//   production: true,
//   // uriApi: "http://portallamisbrasil.test/api/public/api/",
//   linkAsset: "https://lamisbrasil.com.br/",
//   apiUrl: "http://10.0.0.160:8000/api/",
//   // apiUrl: "https://api.lamisbrasil.com.br/api/",
//   linkSite: "https://lamisbrasil.com.br/",
//   version: "2.1"
//   mapsKey: "AIzaSyBA5LCw_HCXDEwGEeBjEi-m02vwyup7VPg",
// };


// Produção
export const environment = {
  
  production: true,
  linkAsset: "https://lamisbrasil.com.br/",
  apiUrl: "https://api.teste.lamisbrasil.com.br/api/",
  linkSite: "https://lamisbrasil.com.br/",
  mapsKey: "AIzaSyB2nEhRGV6vB2es7Kbtvj7IUNiZ0eA7qj8",
  version: "2.2"
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
