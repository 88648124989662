import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  base_url = environment.linkSite;
  base_path = environment.apiUrl;
  data_user:any = {};
  token:any;

  constructor(
  private http: HttpClient,
  private auth: AuthenticationService,
  ) {
    this.loadDataUser();
  }

  httpOptions = {

  };
  httpOptions_file = {

  };

  async loadDataUser() {
    this.token = await this.auth.getUserLogged();

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Version-API': environment.version,
        'Authorization': 'Bearer '+this.token.replace('"', '')
      })
    };
    this.httpOptions_file = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Version-API': environment.version,
        'Authorization': 'Bearer '+this.token.replace('"', ''),
        'Accept':'application/json',
      })
    }
  }

  public deletePrestacaoConta(id) {
    return this.http.post(this.base_path+"delete-prestacao-conta",{id}, this.httpOptions);
  }

  public editPrestacaoConta(id) {
    return this.http.post(this.base_path+"editar-prestacao-conta",{id}, this.httpOptions);
  }
  public getUserLogged() {
    return this.http.post(this.base_path+"get-user-logged",null, this.httpOptions);
  }

  getComunicados(tipo) {
    return this.http.post(this.base_path+"comunicados",{tipo:tipo},this.httpOptions);
  }

  getMessages() {
    this.loadDataUser()
    return this.http.post(this.base_path+"mensagens",null, this.httpOptions);
  }
  get_inventario() {
    return this.http.post(this.base_path+"inventario_do_lider",null, this.httpOptions);
  }
  escala_no_app() {
    return this.http.post(this.base_path+"escala-no-app",null, this.httpOptions);
  }
  cadastra_escala(dados_escala,tipo) {
    return this.http.post(this.base_path+"app-cadastra-escala",{info_escala: dados_escala, action: tipo},this.httpOptions);
  }

  listcolaborador_ativo() {
    return this.http.post(this.base_path+"list-colaborador-ativo",null, this.httpOptions);
  }

  quantidadeMinhaPosse() {
    return this.http.post(this.base_path+"quantidade-minha-posse",null, this.httpOptions);
  }

  getEscala_inventario(credencial) {
    return this.http.post(this.base_path+"getEscala_inventario",credencial,this.httpOptions);
  }
  bater_ponto(base64image) {
    //console.log('base64',base64image);
    return this.http.post(this.base_path+"bater_ponto",{base64image:base64image},this.httpOptions);
  }

  valida_qrcode(qrcode) {
    return this.http.post(this.base_path+"valida_qrcode",{qrcode:qrcode},this.httpOptions);
  }

  valida_reconhecimento(dados_inventario) {
    return this.http.post(this.base_path+"valida_reconhecimento_novo",dados_inventario,this.httpOptions);
  }
  ref_meses_ponto() {
    return this.http.get(this.base_path+"meses-folhas",this.httpOptions);
  }
  lista_contas_contabeis() {
    return this.http.get(this.base_path+"list-contas-contabeis",this.httpOptions);
  }
  minhas_transferencias_ativos() {
    return this.http.post(this.base_path+"minhas-transferencias-ativos",null, this.httpOptions);
  }
  list_minha_posse() {
    return this.http.post(this.base_path+"list-minha-posse", null, this.httpOptions);
  }
  espelho_ponto(mes_ref:string) {
    return this.http.post(this.base_path+"espelho_ponto_mes",{mes_ref: mes_ref},this.httpOptions);
  }
  prestacao_contas() {
    return this.http.post(this.base_path+"minhas-prestacoes",null, this.httpOptions);
  }
  get_ponto_escritorio() {
    return this.http.post(this.base_path+"get-ponto-escritorio",null, this.httpOptions);
  }
  get_cfg_recfacial() {
    return this.http.post(this.base_path+"get-config-expressoes",{},this.httpOptions);
  }
  gera_qr_code_inventario() {
    return this.http.get(this.base_path+"gera-qr-code-escala", this.httpOptions);
  }

  cadastra_contas(formulario:FormData){

    const headers = new HttpHeaders({
      'Version-API': environment.version,
      'Authorization': 'Bearer ' + this.token.replace('"', ''),
      'Accept': 'application/json'
    });

    return this.http.post(this.base_path + "minhas-prestacoes/create", formulario, { headers });
  }

  transf_ativo(doc_para:string,ativo:string) {
    return this.http.post(this.base_path+"cadastra-ativo-transferencia",{doc_para: doc_para, ativo: ativo},this.httpOptions);
  }
  receber_ativo(ativo:string) {
    return this.http.post(this.base_path+"receber-ativo-transferencia",{ ativo: ativo},this.httpOptions);
  }

  altera_senhaperfil(password:string,repassword:string,idusuario){
    this.auth.getUserData().then(data => this.data_user = data);
    
    return this.http.post(this.base_path+"altera-senha-colaborador",
              {idusuario: idusuario,
                password: password,
                repassword:repassword}
            ,this.httpOptions);
  }

}
